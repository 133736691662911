import React, { useState, useEffect, useContext } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';

import { Hero } from '../components/Industry/Hero';
import { IndustryFAQ } from '../components/Industry/IndustryFAQ';
import { Resources } from '../components/General/Resources';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { WaveDownSVG } from '../components/WaveSVGs/WaveDownSVG';
import { FeaturedLogos } from '../components/Industry/IndustryTestimonial/FeaturedLogos';
import { Related } from '../components/Industry/Related';
import { SEO } from '../components/SEO';
import { SoftwareFeatures } from '../components/Industry/SoftwareFeatures';
import { FeatureModal } from '../components/Industry/SoftwareFeatures/FeatureModal';
import { AbridgedSoftwareFeatures } from '../components/Industry/SoftwareFeatures/AbridgedSoftwareFeatures';
import { IndustryBody } from '../components/Industry/IndustryBody';
// import { Form } from '../components/ContactForm/Form';
import FeatureThumbnailContext from '../context/FeatureThumbnailContext';
import { ThumbnailFeatureModal } from '../components/General/ThumbnailFeatureModal';

const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	background: {
		background: theme.white,
	},
	formWaveDown: {
		marginBottom: '-8rem',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '-4rem',
		},
		[theme.breakpoints.down('xs')]: {
			marginBottom: '-3rem',
		},
	},
	formWaveUp: {
		marginBottom: '-8rem',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '-10rem',
		},
	},
	formCont: {
		padding: '24rem 0',
		[theme.breakpoints.down('md')]: {
			padding: '16rem 0',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '8rem 0',
		},
	},
}));

export default function Industry({
	data: { industry, industryLogos },
	location,
}) {
	const classes = useStyles();
	const iosLogo = industryLogos.edges[0];
	const androidLogo = industryLogos.edges[1];
	const qbLogo = industryLogos.edges[2];
	const zapierLogo = industryLogos.edges[3];
	const shopifyLogo = industryLogos.edges[4];

	const { modalOpen, setModalOpen, selectedThumbnail } = useContext(
		FeatureThumbnailContext
	);

	//state for selected card for features
	const [selectedFeature, setSelectedFeature] = useState(null);

	useEffect(() => {
		setSelectedFeature(industry.featureSection[0]?._key);
	}, []);

	//feature list modal controls
	const [featureModalOpen, setFeatureModalOpen] = useState(false);

	const {
		_id,
		metaTitle,
		metaDescription,
		marketoId,
		pardotUrl,
		contactForm,
		industryHero,
		relatedIndustries,
		relatedHeader,
		formBgImage,
		accentColor,
		softwareFeatures,
		featuredInLogos,
		featuredInHeader,
		supportedProductsHeader,
		supportedProductsSubheader,
		faq,
		faqTitle,
		resourceTitle,
		resources,
		_rawResourceBody,
	} = industry;

	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />
			<Hero hero={industryHero} accentColor={accentColor[0]?.hexValue} />
			<IndustryBody
				industry={industry}
				iosLogo={iosLogo}
				androidLogo={androidLogo}
				qbLogo={qbLogo}
				zapierLogo={zapierLogo}
				shopifyLogo={shopifyLogo}
				setFeatureModalOpen={setFeatureModalOpen}
			/>

			<Container>
				<FeaturedLogos
					featuredInLogos={featuredInLogos}
					featuredHeader={featuredInHeader}
				/>
				{[
					'5921cb3a-f3d4-47c1-9f67-26b8d10d63f8',
					'6970c719-613c-475b-b27a-a5acea68444f',
				].includes(softwareFeatures._id) ? (
					<AbridgedSoftwareFeatures
						header={supportedProductsHeader}
						subheader={supportedProductsSubheader}
						softwareFeatures={softwareFeatures}
						accentColor={accentColor[0].hexValue}
						teamLite={
							softwareFeatures._id === '6970c719-613c-475b-b27a-a5acea68444f'
						}
					/>
				) : (
					<SoftwareFeatures
						header={supportedProductsHeader}
						subheader={supportedProductsSubheader}
						softwareFeatures={softwareFeatures}
						accentColor={accentColor[0].hexValue}
					/>
				)}
			</Container>
			<WaveDownSVG height='213' width='100%' fill='#FFFFFF' />
			<div style={{ background: '#f4f8ff' }}>
				<Container>
					<IndustryFAQ header={faqTitle} faq={faq} />
				</Container>
			</div>
			<WaveUpSVG height='213' width='100%' fill='#fff' />
			<div
				className={classes.relatedBackground}
				style={{ background: '#FFFFFF' }}>
				<Container>
					<Related
						related={relatedIndustries}
						header={relatedHeader}
						accentColor={accentColor}
					/>
				</Container>
			</div>

			<div>
				<WaveDownSVG height='213' width='100%' fill='#FFFFFF' />
			</div>

			<div
				className={classes.formCont}
				style={{
					backgroundImage: `url(${formBgImage?.asset?.gatsbyImageData?.images.fallback.src})`,
					backgroundSize: 'cover',
				}}>
				{!!selectedFeature ? (
					<Form
						formId={marketoId}
						pardotUrl={pardotUrl}
						contactForm={contactForm}
						privacy
						modal={false}
						location={location}
					/>
				) : null}
			</div>
			<WaveUpSVG height='213' width='100%' fill='#FFFFFF' />
			<FeatureModal
				header={supportedProductsHeader}
				subheader={supportedProductsSubheader}
				softwareFeatures={softwareFeatures}
				open={featureModalOpen}
				setFeatureModalOpen={setFeatureModalOpen}
				accentColor={accentColor[0].hexValue}
			/>
			<Container>
				<Resources
					header={resourceTitle}
					resources={resources}
					subheader={_rawResourceBody}
				/>
			</Container>
			<div>
				<WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
			</div>
			<ThumbnailFeatureModal
				open={modalOpen}
				setFeatureModalOpen={setModalOpen}
				wistiaLink={selectedThumbnail}
				feature
			/>
		</>
	);
}

export const query = graphql`
	query ($slug: String!) {
		industry: sanityIndustries(slug: { current: { eq: $slug } }) {
			_id
			title
			metaTitle
			metaDescription
			accentColor {
				hexValue
			}
			industryHero {
				ctaText
				_rawContent
				backgroundImage {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
			}
			overviewHeader
			_rawOverviewBody
			overviewImage {
				asset {
					gatsbyImageData(placeholder: BLURRED)
				}
			}
			multiuseCta {
				_rawCtaHeader
				ctaSubheader
				ctaText
				ctaLink
				internalLink
				ctaBackground {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
				mobileCtaBackground {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
			}
			featureHeader
			featureSection {
				_key
				title
				cardTitle
				cardFaIcon
				cardIconColor {
					hexValue
				}
				bodySubHeader
				bodyHeader
				bodySubHeader
				image {
					featureImage {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
				}
				videoVariant
				androidLink
				iosLink
				qbLink
				ctaText
				# svgVariant
				thumbnailVideoUrl
				_rawBodyCopy
			}
			rmSpecialFeature {
				title
				bodySubHeader
				bodyHeader
				bodySubHeader
				logoHeader
				image {
					featureImage {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
				}
				videoVariant
				ctaText
				_rawBodyCopy
			}
			supportedProductsHeader
			supportedProductsSubheader
			# supportedProducts {
			# 	cards {
			# 		title
			# 		cardTopLogo {
			# 			asset {
			# 				gatsbyImageData(placeholder: BLURRED)
			# 			}
			# 		}
			# 		ctaText
			# 		link
			# 		cardBottomImage {
			# 			asset {
			# 				gatsbyImageData
			# 			}
			# 		}
			# 		_rawCardText
			# 	}
			# }
			softwareFeatures {
				_id
				title
				comparisonHeader
				featureListCta
				associatedProducts {
					cards {
						title
					}
				}
				features {
					featureTitle
					featureDescription
					colOne
					colTwo
					colThree
				}
				ctaLineTitle
				ctaRows {
					ctaText
					ctaLink
				}
				expandText
				hideText
				expandedListHeader
				expandedListSubheader
				softwareLogoAndDescription {
					softwareComparisonTitle
					logos {
						asset {
							gatsbyImageData(placeholder: BLURRED, height: 85)
						}
					}
				}
			}
			faqTitle
			faq {
				question
				_rawAnswer
			}
			testimonialTitle
			testimonial {
				title
				header
				testimonialLogo {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
				company
				testimonialText
				nameAndTitle
				videoVariant
				image {
					asset {
						gatsbyImageData(
							fit: FILLMAX
							height: 450
							width: 775
							placeholder: BLURRED
						)
					}
				}
			}
			featuredInHeader
			featuredInLogos {
				featuredInLogoTitle
				featuredInLogo {
					asset {
						gatsbyImageData(placeholder: BLURRED, width: 212)
					}
				}
			}
			relatedHeader
			relatedIndustries {
				faIcon
				accentColor {
					hexValue
				}
				title
				slug {
					current
				}
			}
			resourceTitle
			_rawResourceBody
			resources {
				title
				blurb
				image {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
				ctaText
				ctaLink
			}
			contactForm {
				header
				privacyPolicy
			}
			formBgImage {
				asset {
					gatsbyImageData(placeholder: BLURRED)
				}
			}
			marketoId
			pardotUrl
			customerLogos {
				logo {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
				title
			}
			customerLogosHeader
		}
		industryLogos: allSanityAppLogos {
			edges {
				node {
					appLogoTitle
					appLogo {
						asset {
							gatsbyImageData
						}
					}
				}
			}
		}
	}
`;
